import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import PrimaryButton from "../common/PrimaryButton";
import GhostButton from "../common/GhostButton";

const InstagramConnect = (props) => {

    return (
        <div className="flex justify-center items-center flex-col mt-8 shadow rounded-lg bg-white py-4 px-4">
            <h1 className="text-2xl font-bold">Connect Instagram</h1>
            <h2 className="mt-4 text-center">You can connect instagram and post content directly from ReelKite to
                instagram.</h2>
            <a className="flex mt-4" href={`https://www.facebook.com/dialog/oauth?client_id=1074891246925356&display=page&extras={%22setup%22:{%22channel%22:%22IG_API_ONBOARDING%22}}&redirect_uri=${props.callback_url}&state=${props.brandId}&config_id=1237643580528688&response_type=code&scope=instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights,pages_show_list,pages_read_engagement,instagram_content_publish,business_management`}>
                <PrimaryButton text="Connect Instagram"/>
            </a>
            <div className="mt-4 text-textCaption">We will never post without your permission</div>
            <a href="/shorts">
                <GhostButton text="Skip for now" onClick={() => console.log('Skip for now')}/>
            </a>
        </div>
    )
}

InstagramConnect.propTypes = {
    callback_url: PropTypes.string,
    brandId: PropTypes.number
};

export default InstagramConnect
