import React, {useEffect, useState} from "react"
import {Popover, PopoverContent, PopoverTrigger} from "../ui/popover";
import {Info} from "lucide-react";
import {RadioGroup, RadioGroupItem} from "../ui/radio-group";
import {Label} from "../ui/label";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../ui/tabs";
import TextareaAutosize from "react-textarea-autosize";
import {Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue} from "../ui/select";
import PrimaryButton from "../common/PrimaryButton";
import {showJetToast} from "../Toast";
import ImageStyles from "../short/ImageStyles";
import imageStylesData from "../short/image_styles.json";
import {AudioSelection} from "../common/AudioSelection";
import audioStylesData from "../short/audio_styles.json";
import backgroundMusicData from "../short/background_music.json";
import {Slider} from "../ui/slider";
import TextStyles from "../short/TextStyles";
import textStylesData from "../short/text_styles.json";
import {Turbo} from '@hotwired/turbo-rails';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "../ui/alert-dialog";
import {DateValue} from "react-aria";
import {fromDate, getLocalTimeZone} from "@internationalized/date";
import TimeSlotSelector from "../common/TimeSlotSelector";
import moment from "moment-timezone";
import {LanguageSelection} from "../common/LanguageSelection";

const timeZones = moment.tz.names().map(tz => {
    const offset = moment.tz(tz).utcOffset();
    const hours = Math.floor(offset / 60);
    const minutes = Math.abs(offset % 60);
    const sign = offset >= 0 ? '+' : '-';
    const formattedOffset = `${sign}${String(Math.abs(hours)).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

    return {
        value: tz,
        label: `${tz} (UTC${formattedOffset})`
    };
});

const CreateShortAutomation = ({isPaidUser, scriptPrompts, brand, lastGenerationData, languages}) => {
    const [language, setLanguage] = useState(languages.find((language) => language.code === (brand.generation_data?.generation_prompts?.language || "en")));
    const [script, setScript] = useState("");
    let previousScriptPrompt = brand.generation_data?.generation_prompts?.text_generation_prompt || "";
    const noPrompt = {prompt: "None", title: "None"}
    let previousSelectedPrompt = scriptPrompts.filter((prompt: {
        title: any;
    }) => prompt.title == brand?.generation_data?.generation_prompts?.prompt_title)?.[0];
    if (brand.generation_data?.generation_prompts?.prompt_title != "None") {
        previousScriptPrompt = "";
    }
    if (previousSelectedPrompt == null) {
        previousSelectedPrompt = noPrompt;
    }
    const [scriptPrompt, setScriptPrompt] = useState(previousScriptPrompt);
    const [myScriptPrompts, setMyScriptPrompts] = useState(scriptPrompts);
    const [selectedPrompt, setSelectedPrompt] = useState(previousSelectedPrompt);
    const [generatingScript, setGeneratingScript] = useState(false);
    const [textGenerationPrompt, setTextGenerationPrompt] = useState('');
    const [selectedBackgroundMusic, setSelectedBackgroundMusic] =
        useState(brand.generation_data?.music_settings || backgroundMusicData.find(music => music.name === "Trending"));
    const [selectedTextStyle, setSelectedTextStyle] = useState({...(brand.generation_data?.text_settings || lastGenerationData?.text_settings || textStylesData[0])});
    const [selectedImageStyle, setSelectedImageStyle] =
        useState(brand.generation_data?.image_settings || imageStylesData[0]);
    const [selectedAudioStyle, setSelectedAudioStyle] =
        useState(brand.generation_data?.voice_settings || audioStylesData[0]);
    const [audioStyles, setAudioStyles] = useState(audioStylesData);
    const [visibility, setVisibility] = useState(brand.visibility || "public");
    const [selectedDays, setSelectedDays] = useState(brand.auto_post_days || [0, 1, 2, 3, 4, 5, 6]);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [voicePlaybackRate, setVoicePlaybackRate] = useState<number>(brand.generation_data?.voice_settings?.voice_playback_rate || 1.2);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [time, setTime] = React.useState<DateValue>(
        brand.auto_post_times.length > 0 ?
            fromDate(new Date(brand.auto_post_times[0]), getLocalTimeZone()) :
            fromDate(new Date(), getLocalTimeZone()).add({minutes: 1})
    );
    const [selectedTimeSlots, setSelectedTimeSlots] = useState( brand.auto_post_time_slots || [{start: 9, end: 12}]);
    const [selectedTimeZone, setSelectedTimeZone] = useState(timeZones.find(tz => tz.value === moment.tz.guess()));
    const scriptLength = script?.length || 0;
    const SCRIPT_MAX_LENGTH = 2000;
    const SCRIPT_MIN_LENGTH = 50;
    const VOICE_PLAYBACK_RATE_MAX = 1.5;

    const handleScriptPromptChange = (e) => {
        setScriptPrompt(e.target.value);
    }

    const handleImageStyleSelected = (style) => {
        setSelectedImageStyle(style);
    }

    const handleAudioStyleSelected = (style) => {
        setSelectedAudioStyle(style);
    }

    const handleBackMusicSelected = (style) => {
        setSelectedBackgroundMusic(style);
    }

    useEffect(() => {
        fetchVoices();
    }, [language]);

    const fetchVoices = async () => {
        try {
            const response = await fetch('/voices.json?language=' + language.code, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                }
            });
            const voices = await response.json();
            if (voices.length === 0) {
                return;
            }
            if (voices.find(voice => voice.id === selectedAudioStyle.id) === undefined) {
                // If selected voice is not available for selected language, set first voice as selected
                setSelectedAudioStyle(voices[0]);
            }
            setAudioStyles(voices)
        } catch (error) {
            console.error('Error fetching voices:', error);
        }
    }

    const validateForm = () => {
        const prompt = validateAndSetGenerationPrompt();
        if (prompt == null) {
            return false;
        }
        return true;
    }

    const onGenerateClick = () => {
        if (!validateForm()) {
            return;
        }
        setShowConfirmationPopup(true);
    }

    const handleSubmit = async (event) => {
        if (event) {
            event.preventDefault();
        }
        if (!validateForm()) {
            return;
        }
        if (submitLoading) {
            return;
        }
        try {
            let url = `/pages/${brand.id}/save_short_automation.json`
            let method = 'POST';
            setSubmitLoading(true);
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({
                    generation_data: {
                        image_settings: selectedImageStyle,
                        voice_settings: {...selectedAudioStyle, voice_playback_rate: voicePlaybackRate},
                        music_settings: selectedBackgroundMusic,
                        text_settings: selectedTextStyle,
                        generation_prompts: {
                            text_generation_prompt: textGenerationPrompt || selectedPrompt.prompt,
                            prompt_title: selectedPrompt.title,
                            language: language.code,
                        }
                    },
                    auto_post: true,
                    auto_post_days: selectedDays,
                    auto_post_time_slots: selectedTimeSlots,
                    auto_post_time_zone: selectedTimeZone.value,
                    // auto_post_times: [time.toDate("UTC")],
                    visibility: visibility
                }),
            });
            const shortsResponse = await response.json();
            console.log(shortsResponse);
            if (!response.ok) {
                // @ts-ignore
                for (let [key, errors] of Object.entries(shortsResponse)) {
                    if (Array.isArray(errors)) {
                        errors.forEach((error) => showJetToast(`${key} ${error}`));
                    } else if (typeof errors === 'string') {
                        showJetToast(errors);
                    }
                }
                setSubmitLoading(false);
                return;
            }
            // const shortUrl = `/shorts/${shortsResponse.id}`;
            // const frame = document.getElementById("modal");
            // // @ts-ignore
            // frame.src = shortUrl;
            // // @ts-ignore
            // frame.reload();
            showJetToast("Automation created successfully");
            Turbo.visit("/");
            setSubmitLoading(false);
        } catch (error) {
            console.error('Error submitting automation:', error);
            showJetToast("Error creating automation");
            setSubmitLoading(false);
        }
    };

    const validateAndSetGenerationPrompt = () => {
        const isPromptSelected = selectedPrompt != null && selectedPrompt.prompt !== noPrompt.prompt;
        const isScriptPromptValid = scriptPrompt && scriptPrompt.trim().length > 0;
        if (!isPromptSelected && !isScriptPromptValid) {
            showJetToast("Please select or enter a script prompt");
            return null;
        }
        if (isScriptPromptValid && isPromptSelected) {
            showJetToast("Either enter prompt or select predefined prompts, not both.");
            return null;
        }
        let prompt = '';
        if (isScriptPromptValid) {
            prompt = scriptPrompt;
        } else {
            prompt = selectedPrompt.prompt;
        }
        setTextGenerationPrompt(prompt);
        return prompt;
    }


    const onGenerateScriptClick = async () => {
        let prompt = validateAndSetGenerationPrompt();
        if (prompt == null) {
            return;
        }
        prompt = prompt.trim() + ` Please generate the output in ${language.name}`;
        try {
            let url = '/shorts/test_script_automation.json';
            setGeneratingScript(true);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({
                    prompt: prompt,
                    language: language.code
                }),
            });
            const scriptResponse = await response.json();
            console.log(scriptResponse);
            if (!response.ok) {
                // @ts-ignore
                showToastView(scriptResponse || "Error generating script");
                setGeneratingScript(false);
                return;
            }
            setScript(scriptResponse.script);
            showJetToast("Script generated successfully");
            setGeneratingScript(false);
        } catch (error) {
            setGeneratingScript(false);
            console.error('Error generating script:', error);
            showJetToast("Error generating script");
        }
    }

    const setDate = (date: Date) => {
        setTime(fromDate(date, getLocalTimeZone()));
    }

    return (
        <div className="w-full">
            <p className="mt-6">To fully automate your Instagram, TikTok page or Youtube Channel there are <span
                className="font-bold">three steps</span> that need to be completed on ReelKite AI</p>
            <div className="my-4">
                <h3 className="text-lg font-medium">Step 1: Select or Create a prompt for your video</h3>
                <div className="my-4">
                    <Popover>
                        <PopoverTrigger>
                                    <span className={"flex flex-row gap-2 items-center"}>
                                        Select Language
                                    <Info size={14}/>
                                    </span>
                        </PopoverTrigger>
                        <PopoverContent side={"top"} className={"max-w-5xl"}>The language your video script will be
                            in.</PopoverContent>
                    </Popover>
                </div>
                <LanguageSelection
                    languages={languages}
                    selectedLanguage={language}
                    onLanguageSelected={(language) => setLanguage(language)}
                />
                {language.code !== "en" && !isPaidUser ? <div className="mx-4 text-sm mt-2 text-gray-500">
                    We suggest upgrading to our paid plans to get better results with languages other than English.
                </div>: null}
            </div>

            <div
                className={`rounded-lg font-bold py-3 text-center block bg-white text-textCaption`}>
                Make Prompt for video
            </div>
            <div className="bg-surface p-4 rounded shadow-md">
                <Tabs defaultValue="generate">
                    <TabsList className="bg-gray-200">
                        <TabsTrigger value="generate">Select Prompt</TabsTrigger>
                        <TabsTrigger value="script">Type Prompt</TabsTrigger>
                    </TabsList>
                    <TabsContent value="script">
                        <div className="my-2">
                            <TextareaAutosize
                                id="script_prompt"
                                name="script_prompt"
                                minRows={6}
                                placeholder={"Generate a short story about ..."}
                                onChange={handleScriptPromptChange}
                                value={scriptPrompt}
                                className={`block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full`}
                            />
                        </div>
                    </TabsContent>
                    <TabsContent value="generate">
                        <div className="my-2" onClick={(event) => {
                            event.preventDefault();
                            return false;
                        }}>
                            Choose to generate script from our prompts.
                            <Select
                                value={selectedPrompt?.prompt}
                                onValueChange={(value) => setSelectedPrompt(myScriptPrompts.find(prompt => prompt.prompt === value) || noPrompt)}>
                                <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Select Prompt"/>
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectLabel>Prompts</SelectLabel>
                                        <SelectItem key={-1}
                                                    value={noPrompt.prompt}><span>{noPrompt.title}</span></SelectItem>
                                        {myScriptPrompts.map((prompt, index) => (
                                            <SelectItem key={index}
                                                        value={prompt.prompt}><span>{prompt.title}</span></SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                    </TabsContent>
                </Tabs>
                <PrimaryButton text={"Test Prompt"} onClick={onGenerateScriptClick}
                               isLoading={generatingScript}/>
            </div>

            <div className="mt-5 mb-3">
                <label htmlFor="script">
                    <Popover>
                        <PopoverTrigger>
                                    <span className={"flex flex-row gap-2 items-center"}>
                                        Script
                                    <Info size={14}/>
                                    </span>
                        </PopoverTrigger>
                        <PopoverContent side={"top"} className={"text-sm max-w-5xl"}>Below will be a sample for your
                            automation script once you click Test Prompt.</PopoverContent>
                    </Popover>
                </label>
                <TextareaAutosize
                    id="script"
                    name="script"
                    minRows={6}
                    disabled={true}
                    value={script}
                    className={`bg-gray-200 block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full`}
                />
            </div>
            <h3 className="text-lg my-4 font-medium">Step 2: Select visual settings for your video</h3>
            <div className="text-lg my-4">
                Choose a visual style for your video
            </div>
            <ImageStyles
                selectedImageStyle={selectedImageStyle}
                imageStyles={imageStylesData.filter(style => style?.hidden !== true)}
                onImageStyleSelected={handleImageStyleSelected}/>

            <div className="flex flex-col  lg:flex-row lg:justify-around">
                <div>
                    <div className="text-lg my-4">
                        Choose a voice over
                    </div>
                    <AudioSelection
                        isPaidUser={isPaidUser}
                        selectedAudioStyle={selectedAudioStyle}
                        audioStyles={audioStyles}
                        playbackRate={voicePlaybackRate}
                        placeholder={"Select voice"}
                        searchPlaceholder={"Search voice..."}
                        onAudioStyleSelected={handleAudioStyleSelected}/>
                </div>
                <div>
                    <div className="text-lg my-4">
                        Select Background music
                    </div>
                    <AudioSelection
                        isPaidUser={isPaidUser}
                        selectedAudioStyle={selectedBackgroundMusic}
                        audioStyles={backgroundMusicData.sort((a, b) => a.position - b.position)}
                        placeholder={"Select music"}
                        searchPlaceholder={"Search music..."}
                        onAudioStyleSelected={handleBackMusicSelected}/>
                </div>
            </div>
            <div className="my-4">
                <div className="text-lg my-2">
                    <Popover>
                        <PopoverTrigger>
                                    <span className={"flex flex-row gap-2 items-center"}>
                                       Playback speed for voice <span
                                        className="text-sm">(1x-{VOICE_PLAYBACK_RATE_MAX}x)</span>
                                    <Info size={14}/>
                                    </span>
                        </PopoverTrigger>
                        <PopoverContent side={"top"} className={"text-sm max-w-5xl"}>You can play the voiceover above
                            after changing playback rate to get idea of speed of audio.</PopoverContent>
                    </Popover>
                </div>
                <div className="mx-6 mt-4">
                    <Slider
                        min={1}
                        value={[voicePlaybackRate]}
                        max={VOICE_PLAYBACK_RATE_MAX}
                        className={"showStepBar"}
                        step={0.1}
                        onValueChange={(value) => setVoicePlaybackRate(value[0])}/>
                </div>
            </div>

            <div className="text-lg my-4">
                Caption Style
            </div>
            <TextStyles
                textStyles={textStylesData}
                selectedTextStyle={selectedTextStyle}
                onTextStyleSelected={setSelectedTextStyle}
            />

            <h3 className="text-lg mt-4 font-medium">Step 3: Select your video frequency and time slot.</h3>
            <p className="text-sm my-2 text-textCaption">You can select multiple time slots, one video will be generated per time slot you select. We will post on optimal time within that time slot.</p>
            <TimeSlotSelector
                timeZones={timeZones}
                selectedTimeSlots={selectedTimeSlots} setSelectedTimeSlots={setSelectedTimeSlots}
                              selectedTimeZone={selectedTimeZone} setSelectedTimeZone={setSelectedTimeZone}/>
            <p className="text-normal text-blue-600 mt-4">We recommend selecting one or two slot only. Posting daily for a longer duration (1-2 month) helps in getting more views on different platforms like Youtube/TikTok/SnapChat. If you select multiple slots, multiple videos will be posted daily and your credits will be consumed faster.</p>

            {/*<div className="my-4">*/}
            {/*    <WeekdayPicker setSelectedDays={setSelectedDays} selectedDays={selectedDays}/>*/}
            {/*    <TimePickerDemo date={time.toDate(getLocalTimeZone())} setDate={setDate}/>*/}
            {/*</div>*/}

            <div className="my-4">
                <div className="text-lg font-medium">Visibility <span className="text-normal">
                    For social platforms that support visibility of posts
                </span>
                </div>
                <div className="my-4">
                    <Select
                        value={visibility}
                        onValueChange={(value) => setVisibility(value)}>
                        <SelectTrigger className="w-full">
                            <SelectValue placeholder="Select Content Visibility"/>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectLabel>Content Visibility on Social Platforms</SelectLabel>
                                <SelectItem key={"public"} value={"public"}><span>public</span></SelectItem>
                                <SelectItem key={"private"} value={"private"}><span>private</span></SelectItem>
                                <SelectItem key={"unlisted"} value={"unlisted"}><span>unlisted</span></SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            </div>


            <AlertDialog open={showConfirmationPopup} onOpenChange={setShowConfirmationPopup}>
                <AlertDialogTrigger>
                    <PrimaryButton text={"Save Automation"} onClick={onGenerateClick}/>
                </AlertDialogTrigger>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Save automation?</AlertDialogTitle>
                        <AlertDialogDescription>
                            This will run your automation on selected time and days. You can edit this later in
                            settings.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={handleSubmit}>Save</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>

        </div>
    )
}

CreateShortAutomation.propTypes = {};

export default CreateShortAutomation
